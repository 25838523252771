import React, { useMemo } from 'react';
// import { useNavigate } from "react-router-dom";
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import './UserPanel.scss';


export default function UserPanel({ menuMode }) {
  const { user, signOut } = useAuth();
  // const navigate = useNavigate();

  // function navigateToProfile() {
  //   navigate("/profile");
  // }
  const menuItems = useMemo(() => ([
    // {
    //   text: 'Profil',
    //   icon: 'user',
    //   onClick: navigateToProfile
    // },
    {
      text: 'Çıkış yap',
      icon: 'runner',
      onClick: signOut
    }
  ]), [signOut]);
  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <img src='./logo_icon.png' />
        <div className={'user-name'}>{user.username}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={'top center'} at={'bottom center'} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}
