import defaultUser from "../utils/default-user";
const API_HOST = process.env.REACT_APP_HOST;
const API_PORT = process.env.REACT_APP_PORT;
export async function signIn(username, password) {
  try {
    const response = await fetch("https://"+API_HOST+":"+API_PORT+"/login", {
      method: "POST",
      body: JSON.stringify({ username: username, password: password }),
      headers: {
        "Content-Type": "application/json",
      },
      
    });
    
    const res = await response.json();
    if (res.status === 201 && res.error === null) {
      return {
        isOk: true,
        data: res.session,
      };
    } else {
      return {
        isOk: false,
        message: res.message,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}

export async function getUser(user) {
  if (user !== null) {
    try {
      const response = await fetch("https://"+API_HOST+":"+API_PORT+"/user", {
        method: "POST",
        body: JSON.stringify({ ...user }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const res = await response.json();
      if (res.code === 500) {
        return {
          isOk: true,
          data: localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")) : "",
        };
      } else {
        return {
          isOk: true,
          data: res.data,
        };
      }

    } catch {
      return {
      };
    }
  }
  return {
    isOk: false,
  };
}

export async function createAccount(email, password) {
  try {
    // Send request

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to create account",
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to change password",
    };
  }
}

export async function resetPassword(email) {
  try {
    // Send request

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to reset password",
    };
  }
}