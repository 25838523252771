import React, { useEffect, useState, useRef  } from "react";
import "./users.scss";
import CustomStore from "devextreme/data/custom_store";
import {
  DataGrid,
  HeaderFilter,
  FilterRow,
  Editing,
  Column,
  Form,
  Item,
  Lookup,
  Popup,
  Texts
} from "devextreme-react/data-grid";

let policyId = [];

const API_HOST = process.env.REACT_APP_HOST;
const API_PORT = process.env.REACT_APP_PORT;

const data = new CustomStore({
  key: "id",
  load: async () => {
    try {
      const response = await fetch("https://"+API_HOST+":"+API_PORT+"/users");
      return await response.json();
    } catch {
      throw new Error("Network Error");
    }
  },
  insert: async (values) => {
    const response = await fetch("https://"+API_HOST+":"+API_PORT+"/user", {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  },
  update: async (key, values) => {
    values.id = key;
    const response = await fetch("https://"+API_HOST+":"+API_PORT+"/user/update", {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  },
  remove: async (policyId) => {

    var id = { id: policyId };

    const response = await fetch("https://" + API_HOST + ":" + API_PORT + "/user/delete", {
      method: "POST",
      body: JSON.stringify(id),
      headers: {
        "Content-Type": "application/json",
      },
    })
      //.then(handleErrors);
      return await response.json();

  },
});

const userTypes = [
  { key: 1, value: "Admin" },
  { key: 2, value: "Monitor" },
];

export default function Users() {
  const [companies, setCompanies] = useState();
  const dataGridRef = useRef();
  const [editKey, setEditKey] = useState();

  const upd = () =>
    data
      .update(editKey, { policies: policyId })
      .then(() => dataGridRef.current.instance.refresh());

  useEffect(() => {
    fetch("https://"+API_HOST+":"+API_PORT+"/companies") //api for the get request
      .then((response) => response.json())
      .then((res) => setCompanies(res.data));
  }, []);

  return (
    <>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
        <div className="dx-fieldset-header">Kullanıcılar</div>
          <DataGrid
            dataSource={data}
            keyExpr="id"
            showBorders={true}
            remoteOperations={false}
            ref={dataGridRef}
            onEditingStart={(e) => setEditKey(e.key)}
            onSaving={upd}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Editing
              mode="popup"
              allowAdding={true}
              editRowKey="id"
              allowUpdating={true}
              allowDeleting={true}
              useIcons={true}
              onSaving={upd}
            >
              <Texts
                      saveRowChanges="Kaydet"
                      cancelRowChanges="Vazgeç"
                      confirmDeleteMessage="Seçtiğiniz kullanıcı bilgileri kalıcı olarak silinecektir.Onaylıyor musunuz ?"
                      addRow="Ekle"
                      deleteRow="Sil"
                      editRow="Düzenle"
                />
              <Popup title="Kullanıcı Ekle" showTitle={true} width={900} height={250} />
              <Form>
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="username" />
                  <Item dataField="password" />
                  <Item dataField="user_type" />
                  <Item dataField="company_id" />
                </Item>
              </Form>
            </Editing>
            <Column dataField="username" caption="Kullanıcı adı" />
            <Column dataField="password" caption="Parola" visible={false} />
            <Column dataField="user_type" caption="Kullanıcı Türü" allowFiltering={false} editorOptions={{placeholder: "Seç..."}}>
              <Lookup
                dataSource={userTypes}
                valueExpr="key"
                displayExpr="value"
                allowClearing={true}
              />
            </Column>
            <Column dataField="company_name" caption="Firma Adı" />
            <Column dataField="company_id" caption="Firma Adı" visible={false} editorOptions={{placeholder: "Seç..."}}>
              <Lookup
                dataSource={companies}
                valueExpr="id"
                displayExpr="name"
                allowClearing={true}
              />
            </Column>
            <Column dataField="created_at" caption="Oluşturma Tarihi" />
          </DataGrid>
        </div>
      </div>
    </>
  );
}
