import React, { useEffect, useState, useRef } from "react";
import CustomStore from "devextreme/data/custom_store";
import {
  DataGrid,
  HeaderFilter,
  FilterRow,
  Editing,
  Column,
  Form,
  Item,
  Lookup,
  Popup,
  Texts
} from "devextreme-react/data-grid";

let policyId = [];

const API_HOST = process.env.REACT_APP_HOST;
const API_PORT = process.env.REACT_APP_PORT;

const data = new CustomStore({

  key: "id",
  load: async () => {
    try {
      const response = await fetch("https://"+API_HOST+":"+API_PORT+"/locations");
      return await response.json();
    } catch {
      throw new Error("Network Error");
    }
  },
  insert: async (values) => {
    const response = await fetch("https://"+API_HOST+":"+API_PORT+"/locations/add", {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  },
  update: async (key, values) => {
    values.id = key;
    const response = await fetch("https://"+API_HOST+":"+API_PORT+"/locations/update", {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  },
  remove: async (policyId) => {

    var id = { id: policyId };

    const response = await fetch("https://" + API_HOST + ":" + API_PORT + "/locations/delete", {
      method: "POST",
      body: JSON.stringify(id),
      headers: {
        "Content-Type": "application/json",
      },
    })
      //.then(handleErrors);
      return await response.json();
  },
});

export default function Locations() {
  const [companies, setCompanies] = useState();
  const [editKey, setEditKey] = useState();
  const dataGridRef = useRef();

  const upd = () =>
  data
    .update(editKey, { policies: policyId })
    .then(() => dataGridRef.current.instance.refresh());

  useEffect(() => {
    fetch("https://"+API_HOST+":"+API_PORT+"/companies") //api for the get request
      .then((response) => response.json())
      .then((res) => setCompanies(res.data));
  }, []);

  return (
    <>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
        <div className="dx-fieldset-header">Lokasyonlar</div>
          <DataGrid
            dataSource={data}
            keyExpr="id"
            showBorders={true}
            remoteOperations={false}
            ref={dataGridRef}
            onEditingStart={(e) => setEditKey(e.key)}
            onSaving={upd}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Editing
              mode="popup"
              allowAdding={true}
              editRowKey="id"
              allowUpdating={true}
              allowDeleting={true}
              useIcons={true}
              onSaving={upd}
            >
              <Texts
                      saveRowChanges="Kaydet"
                      cancelRowChanges="Vazgeç"
                      confirmDeleteMessage="Seçtiğiniz lokasyon bilgileri kalıcı olarak silinecektir.Onaylıyor musunuz ?"
                      addRow="Ekle"
                      deleteRow="Sil"
                      editRow="Düzenle"
                />
              <Popup title="Lokasyon Ekle" showTitle={true} width={900} height={350} />
              <Form>
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="company_id" />
                  <Item dataField="name" />
                  <Item dataField="ip_address" />
                  <Item dataField="api_key" />
                  <Item dataField="api_user" />
                </Item>
              </Form>
            </Editing>
            <Column dataField="company_name" caption="Firma" />
            <Column dataField="company_id" caption="Firma" visible={false} editorOptions={{placeholder: "Seç..."}}>
              <Lookup
                dataSource={companies}
                valueExpr="id"
                displayExpr="name"
                allowClearing={true}
              />
            </Column>
            <Column dataField="name" caption="Lokasyon" />
            <Column dataField="ip_address" caption="IPv4" />
            <Column dataField="api_key" caption="API Key" />
            <Column dataField="api_user" caption="API Kullanıcı Adı" />
          </DataGrid>
        </div>
      </div>
    </>
  );
}
