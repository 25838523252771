export const navigation = [
  {
    text: "Anasayfa",
    path: "/home",
    icon: "home",
  },
  {
    text: "Firmalar",
    path: "/companies",
    icon: "product",
  },
  {
    text: "Lisanslar",
    path: "/licenses",
    icon: "fieldchooser",
  },
  {
    text: "Lokasyonlar",
    path: "/locations",
    icon: "map",
  },
  {
    text: "Kullanıcılar",
    path: "/users",
    icon: "group",
  },
 /* {
    text: "Examples",
    icon: "folder",
    items: [
      {
        text: "Profile",
        path: "/profile",
      },
    ],
  },

  {
    text: "Politikalar",
    path: "/policies",
    icon: "folder",
  },*/
];
