import React, { useEffect, useState, useRef } from "react";
import CustomStore from "devextreme/data/custom_store";
import {
  DataGrid,
  FilterRow,
  Editing,
  Column,
  Form,
  Item,
  Lookup,
  Popup,
  Texts
} from "devextreme-react/data-grid";

let policyId = [];

const API_HOST = process.env.REACT_APP_HOST;
const API_PORT = process.env.REACT_APP_PORT;

const data = new CustomStore({
  key: "id",
  load: async () => {
    try {
      const response = await fetch("https://"+API_HOST+":"+API_PORT+"/customer/licenses");
      return await response.json();
    } catch {
      throw new Error("Network Error");
    }
  },
  insert: async (values) => {

    const response = await fetch("https://"+API_HOST+":"+API_PORT+"/licence/add", {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  },
  update: async (key, values) => {
    values.id = key;
    const response = await fetch("https://"+API_HOST+":"+API_PORT+"/licence/update", {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  },
  remove: async (policyId) => {

    var id = { id: policyId };

    const response = await fetch("https://" + API_HOST + ":" + API_PORT + "/licence/delete", {
      method: "POST",
      body: JSON.stringify(id),
      headers: {
        "Content-Type": "application/json",
      },
    })
      //.then(handleErrors);
      return await response.json();
  },
 
});

export default function Licenses() {

  const [companies, setCompanies] = useState();
  const [type, setType] = useState();
  const dataGridRef = useRef();
  const [editKey, setEditKey] = useState();

  const upd = () =>
    data
      .update(editKey, { policies: policyId })
      .then(() => dataGridRef.current.instance.refresh());

  useEffect(() => {
    fetch("https://"+API_HOST+":"+API_PORT+"/companies") //api for the get request
      .then((response) => response.json())
      .then((res) => setCompanies(res.data));
    
  }, []);

  useEffect(() => {
    fetch("https://"+API_HOST+":"+API_PORT+"/licence/types")
    .then((response) => response.json())
    .then((res) => setType(res.data));
  }, []);


  return (
    <>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
        <div className="dx-fieldset-header">Lisanslar</div>
          <DataGrid
            dataSource={data}
            key="id"
            showBorders={true}
            remoteOperations={false}
            ref={dataGridRef}
            onEditingStart={(e) => setEditKey(e.key)}
            onSaving={upd}
          >
            <FilterRow visible={true} />
            <Editing
              mode="popup"
              allowAdding={true}
              allowUpdating={true}
              allowDeleting={true}
              useIcons={true}
              editRowKey="id"
              onSaving={upd}
            >
               <Texts
                      saveRowChanges="Kaydet"
                      cancelRowChanges="Vazgeç"
                      confirmDeleteMessage="Seçtiğiniz lisans bilgileri kalıcı olarak silinecektir.Onaylıyor musunuz ?"
                      addRow="Ekle"
                      deleteRow="Sil"
                      editRow="Düzenle"
                />
              <Popup title="Lisans Ekle" showTitle={true} width={900} height={350} />
              <Form>
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="company_id"/>
                  <Item dataField="licence_type"/>
                  <Item dataField="total" dataType="number"/>
                  <Item dataField="start_date"/>
                  <Item dataField="end_date"/>
                </Item>
              </Form>

            </Editing>
            <Column dataField="company_name" caption="Firma Adı" />
            <Column
              dataField="licence_type"
              caption="Lisans"
              visible={true}
              allowFiltering={false}
              editorOptions={{placeholder: "Seç..."}}
            >
              <Lookup dataSource={type} valueExpr="id" displayExpr="name" allowClearing={true}/>
            </Column>
            <Column dataField="total" caption="Toplam"></Column>
            <Column
              dataField="start_date"
              caption="Başlangıç Tarihi"
              dataType="date"
              allowFiltering={false}
            />
            <Column
              dataField="end_date"
              caption="Bitiş Tarihi"
              dataType="date"
              allowFiltering={false}
            />
            <Column dataField="company_id" caption="Firma Adı" visible={false} editorOptions={{placeholder: "Seç..."}}>
              <Lookup
                dataSource={companies}
                valueExpr="id"
                displayExpr="name"
                allowClearing={true}
              />
            </Column>
            
          </DataGrid>
        </div>
      </div>
    </>
  );
}
