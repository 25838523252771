import {
  HomePage,
  ProfilePage,
  CompaniesPage,
  UsersPage,
  LicensesPage,
  PoliciesPage,
  Locations,
} from "./pages";
import { withNavigationWatcher } from "./contexts/navigation";

const routes = [
  /*{
    path: "/profile",
    element: ProfilePage,
  },*/
  {
    path: "/home",
    element: HomePage,
  },
  {
    path: "/companies",
    element: CompaniesPage,
  },
  {
    path: "/users",
    element: UsersPage,
  },
  {
    path: "/licenses",
    element: LicensesPage,
  },
  /*{
    path: "/policies",
    element: PoliciesPage,
  },*/
  {
    path: "/locations",
    element: Locations,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
