import React, { useState, useEffect, useRef } from "react";
import CustomStore from "devextreme/data/custom_store";
import "./companies.scss";
import {
  DataGrid,
  HeaderFilter,
  FilterRow,
  Editing,
  Column,
  Form,
  Item,
  Popup,
  Texts,
} from "devextreme-react/data-grid";

import List from "devextreme-react/list";
import ArrayStore from "devextreme/data/array_store";
import notify from 'devextreme/ui/notify';

let policyId = [];

const API_HOST = process.env.REACT_APP_HOST;
const API_PORT = process.env.REACT_APP_PORT;


const handleErrors = async (response) => {
  const data = await response.json()
  if (data.status === 400) {
    return (
    notify(data.messages.warning, 'error', 2000)
    )
  } else {
    notify("Silme İşlemi Başarılı", 'success', 2000)
  }
}



const data = new CustomStore({

  key: "id",
  load: async () => {
    try {
      const response = await fetch("https://" + API_HOST + ":" + API_PORT + "/companies");
      return await response.json();
    } catch {
      throw new Error("Network Error");
    }
  },
  insert: async (values) => {
    values.policies = JSON.stringify(policyId);
    const response = await fetch("https://" + API_HOST + ":" + API_PORT + "/company/add", {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  },
  update: async (key, values) => {
    values.id = key;
    values.policies = JSON.stringify(policyId);
    const response = await fetch("https://" + API_HOST + ":" + API_PORT + "/company/update", {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  },

  remove: async (policyId) => {

    var id = { id: policyId };

    const response = await fetch("https://" + API_HOST + ":" + API_PORT + "/company/delete", {
      method: "POST",
      body: JSON.stringify(id),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(handleErrors);
      return await response.json();
  },
});


const policiesType = {
  1: "İzleme Politikası",
  2: "Güvenlik Politikası",
  3: "Temel Politika"
}

export default function Companies() {

  const [policies, setPolicies] = useState([]);
  const [editKey, setEditKey] = useState();
  const dataGridRef = useRef();

  const upd = () =>
    data
      .update(editKey, { policies: policyId })
      .then(() => dataGridRef.current.instance.refresh());

  useEffect(() => {
    fetch("https://" + API_HOST + ":" + API_PORT + "/policy/types") //api for the get request
      .then((response) => response.json())
      .then((res) => setPolicies(res.data));
  }, []);

  const dataSource = new ArrayStore({
    key: "id",
    data: policies,
  });


  return (
    <>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
        <div className="dx-fieldset-header">Firmalar</div>
          <DataGrid
            dataSource={data}
            key="id"
            ref={dataGridRef}
            showBorders={true}
            remoteOperations={false}
            onEditingStart={(e) => setEditKey(e.key)}
            onSaving={upd}
          >
            
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Editing
              mode="popup"
              allowAdding={true}
              allowUpdating={true}
              allowDeleting={true}
              useIcons={true}
              editRowKey="id"
              // onSaving={upd}
            >
              <Texts
                saveRowChanges="Kaydet"
                cancelRowChanges="Vazgeç"
                confirmDeleteMessage="Seçtiğiniz firma bilgileri kalıcı olarak silinecektir.Onaylıyor musunuz ?"
                addRow="Ekle"
                deleteRow="Sil"
                editRow="Düzenle"
              />
              <Popup title="Firma Ekle" showTitle={true} width={900} height={350} />
              <Form>
                <Item itemType="group" colCount={1} colSpan={1}>
                  <Item dataField="name" />
                  <Item dataField="address" />
                  <Item dataField="phone" />
                </Item>
                <Item dataField="policies"></Item>
              </Form>
            </Editing>
            <Column dataField="name" caption="Firma Adı" />
            <Column dataField="address" caption="Adres" />
            <Column dataField="phone" caption="Telefon" />
            <Column
              dataField="policies"
              caption="Politika Profilleri"
              cellRender={(e) => {
                const result = []
                const data = e.data.policies;
                if (data && data.length > 0) {
                  data.forEach(i => {
                    result.push(policiesType[i])
                  });
                }
                return result.join(", ")
              }}
              editCellRender={(e) => {
                return (
                  <List
                    style={{ borderTop: "none" }}
                    dataSource={dataSource}
                    displayExpr="name"
                    showSelectionControls={true}
                    selectionMode={"all"}
                    selectAllMode="page"
                    selectAllText="Hepsini seç"
                    selectedItemKeys={e.data.policies !== undefined ? e.data.policies : []}
                    onOptionChanged={(e) => {
                      if (e.name === "selectedItemKeys") {
                        policyId = e.value;
                      }
                    }}
                  ></List>
                );
              }}
            ></Column>
            <Column dataField="created_at" caption="Oluşturma Tarihi" />
          </DataGrid>
        </div>
      </div>
    </>
  );
} 
