import React, { useState, useEffect, useCallback } from "react";
import './home.scss';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import List from "devextreme-react/list";
import {
  Chart,
  Series,
  ArgumentAxis,
  Legend,
} from 'devextreme-react/chart';

const API_HOST = process.env.REACT_APP_HOST;
const API_PORT = process.env.REACT_APP_PORT;

export default function Home() {

  const [dnsData, setDnsData] = useState([]);
  const [allowData, setAllowData] = useState([])
  const [denyData, setDenyData] = useState([])
  const [allow, setAllow] = useState(0)
  const [deny, setDeny] = useState(0)
  const [time, setTime] = useState()
  const [domain, setDomain] = useState()
  const [title,setTitle] = useState("Toplam")

  useEffect(() => {
    dashData()
  }, []);

const dashData = async () => {
  const data = await fetch("https://"+API_HOST+":"+API_PORT+"/monitor/load",
  {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    }
  })
  const res = data.json();
  const response = await res;
  setDnsData(response.data);
  allowDeny(response.data)

}

const allowDeny = (dnsData) => {

  let blockList = []
  let nonBlockList = []

  dnsData.forEach((e) => {

    if (e.is_blocked === 1) {
      blockList.push(e)
    }
    else {
      nonBlockList.push(e)
    }
  })

  setAllow(nonBlockList.length)
  setDeny(blockList.length)

  setAllowData(nonBlockList)
  setDenyData(blockList)
  selectData("top", dnsData, nonBlockList, blockList)
}

  const selectData = (e, dns, allow, deny) => {
    
    switch (e) {
      case "top":
        getData(dns)
        setTitle("Toplam")
        break;
      case "allow":
        setTitle("Güvenilir")
        getData(allow)
        break;
      case "deny":
        setTitle("Tehlikeli")
        getData(deny)
        break;
      default:
    }
  }

  const getData = (selectData) =>{
    let timeName = []
    let timeList = []
    let domainName = []
    let domainList = []
    selectData.forEach((e) => {
      if (!timeName.includes(e.start_time)) {
        timeName.push(e.start_time)
      }
    })

    timeName.forEach((e) => {
      let val = 0;
        selectData.forEach((i) => {
        if (i.start_time === e) {
          val += 1;
        }
      })
      timeList.push({ dateValue: val })
    })

    timeName.forEach((e) => {
      let a = timeName.indexOf(e)
      timeList[a].date = e
    })

    selectData.forEach((e) => {
      if (!domainName.includes(e.query)) {
        domainName.push(e.query)
      }
    })
    
    domainName.forEach((e) => {
      let val = 0;
      selectData.forEach((i) => {
        if (i.query === e) {
          val += 1;
        }
      })
      domainList.push({ domainValue: val })
    })

    domainName.forEach((e) => {
      let a = domainName.indexOf(e)
      domainList[a].domain = e
    })

    setTime(timeList)
    setDomain(domainList)
  }

  const domainItem = (item) => {
    return (
      <div className="domainList">
        <div>{item.domain}</div>
        <div className="domainValue">{item.domainValue}</div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className={'content-block '}>
        <ResponsiveBox>
          <Row />
          <Col />
          <Col />
          <Col />
          <Item>
            <Location
              row={0}
              col={0}
              screen="lg"
            ></Location>
            <div onClick={()=>{
              selectData("top", dnsData, allowData, denyData)
            }} className="dx-card card1" >
              <img className="img" src="https://img.icons8.com/fluency/256/cyber-security.png" alt="" />
              <div className="title">Toplam</div>
              <div className='subtitle'>Güvenli: {allow}</div>
              <div className='subtitle'>Tehlikeli: {deny}</div>
              <div className='description'>Toplam DNS sorgu sayısı</div>
            </div>
          </Item>
          <Item>
            <Location
              row={0}
              col={1}
              screen="lg"
            ></Location>
            <div onClick={() => {
              selectData("allow", dnsData, allowData, denyData)
            }} className="dx-card card2" >
              <img className="img" src="https://img.icons8.com/fluency/256/security-checked.png" alt="" />
              <div className="title">Güvenli</div>
              <div className='subtitle'>Toplam:  {allow}</div>
              <div className='description'>Haber, Spor, Arama motoru gibi içerik ve güvenlik anlamında risk içermeyen domainleri kapsar</div>
            </div>
          </Item>
          <Item>
            <Location
              row={0}
              col={2}
              screen="lg"
            ></Location>
            <div onClick={() => {
              selectData("deny", dnsData, allowData, denyData)
            }} className="dx-card card3" >
              <img className="img" src="https://img.icons8.com/fluency/256/delete-shield.png" alt="" />
              <div className="title">Tehlikeli</div>
              <div className='subtitle'>Toplam:  {deny}</div>
              <div className='description'>Malware, Botnet, Phishing gibi güvenlik riski yüksek domainleri kapsar</div>
            </div>
          </Item>
        </ResponsiveBox>
        <div className={"dx-card"}>
          <h3>{title}</h3>
          <ResponsiveBox>
            <Row ratio={1}></Row>
            <Col ratio={1}></Col>
            <Col ratio={4}></Col>
            <Item>
              <Location row={0} col={0} />
              <div className="list">
                <List
                  dataSource={domain}
                  itemRender={domainItem}
                  >
                </List>
              </div>
            </Item>
            <Item>
              <Location row={0} col={1} />
              <div className="chart">
                <Chart 
                  id="chart"
                  dataSource={time}
                  
                >
                  <Series argumentField="date" valueField="dateValue" />
                  <ArgumentAxis
                  >
                  </ArgumentAxis>
                  <Legend visible={false} />
                </Chart>
              </div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  )
}
